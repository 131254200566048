import store from '@/store'

export const setDirection = direction => {
  if (direction == 0) {
    store.commit('directions/SET_ACTIVE_EXPOSURE_PART', {
      name: 'Source of Funds',
      value: 'incoming',
    })
    store.commit('directions/SET_EXPOSURE_PART_OPTIONS', [
      { name: 'Source of Funds', value: 'incoming' },
    ])
  }
}
