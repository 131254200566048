<template>
  <div
    v-if="getUgTooltip.state"
    class="user-guide__tooltip"
    @click="redirectToUserGuide"
  >
    <div class="user-guide__tooltip-icon">
      <GlIcon
        :height="24"
        name="ug-book"
        :width="24"
      />
    </div>
    <div class="user-guide__tooltip-info">
      <div class="user-guide__tooltip-header">
        Knowledge Center
      </div>
      <div class="user-guide__tooltip-text">
        Click here to explore a quick guide
      </div>
    </div>
    <div
      class="user-guide__tooltip-close"
      @click.stop="closeTooltip"
    >
      <GlIcon
        :height="24"
        name="ug-close"
        :width="24"
      />
    </div>
  </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Components
import GlIcon from '@/components/gl-icon'

export default {
  components: {
    GlIcon,
  },
  computed: {
    ...mapGetters('userGuide', ['getUgTooltip']),
  },
  methods: {
    ...mapMutations('userGuide', ['SET_UG_TOOLTIP_STATE']),
    redirectToUserGuide() {
      window.open(this.getUgTooltip.link, '_blank')
    },
    closeTooltip() {
      this.SET_UG_TOOLTIP_STATE(false)
    }
  }
}
</script>

<style scoped>
.user-guide__tooltip {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 298px;
  height: 64px;
  padding: 12px 12px 12px 20px;
  background-color: var(--white);
  border: 1px solid var(--user-guide-border);
  border-radius: 8px;
  position: absolute;
  left: 16px;
  bottom: 20px;
  z-index: 100;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
}
.user-guide__tooltip:before {
  content: "";
  width: 8px;
  height: 100%;
  background-color: var(--accent-reflex-blue);
  position: absolute;
  left: 0;
  top: 0;
}
.user-guide__tooltip-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  min-width: 40px;
  background-color: var(--cotton-grey-f-9);
  border-radius: 8px;
}
.user-guide__tooltip-info {
  position: relative;
  z-index: 1;
}
.user-guide__tooltip-header {
  margin-bottom: 4px;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  color: var(--space-cadet);
}
.user-guide__tooltip-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: var(--dark-grey-6-e);
}
.user-guide__tooltip-close {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 8px;
  top: 8px;
  z-index: 2;
}
</style>