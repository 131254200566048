export default {
  SET_UG_PAGINATION_CURRENT_PAGE(state, payload) {
    state.ugPagination.currentPage = payload
  },
  SET_UG_PAGINATION_TOTAL(state, payload) {
    state.ugPagination.totalPages = payload
  },
  SET_UG_CARDS(state, payload) {
    state.ugCards = payload
  },
  SET_UG_TOOLTIP_STATE(state, payload) {
    state.ugTooltip.state = payload
  },
  SET_UG_TOOLTIP_LINK(state, payload) {
    state.ugTooltip.link = payload
  },
  SET_GM_PAGINATION_CURRENT_PAGE(state, payload) {
    state.gmPagination.currentPage = payload
  },
  SET_GM_PAGINATION_TOTAL(state, payload) {
    state.gmPagination.totalPages = payload
  },
  SET_GM_TABLE(state, payload) {
    state.gmTable = payload
  },
}
