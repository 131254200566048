import apiService from '@/utils/api-client'

export default {
  async getRates({ commit }, payload) {
    try {
      const response = await apiService.post('/rates/get', payload)
      commit('SET_ACTIVE_RATES', response.data)
    } catch (error) {
      console.error(error)
      throw error
    }
  }
}