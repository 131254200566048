import { email, required, confirmed, max, max_value, min_value, min } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
import wav from 'wallet-address-validator'
import { getIsTxHash } from '@/utils/get-is-tx-hash'
import store from "@/store";

extend('email', email)

extend('max', { ...max, message: 'max {length} characters' })

extend('min', { ...min, message: 'min {length} characters' })

extend('max_value', max_value)
extend('min_value', min_value)

extend('required', { ...required, message: '{_field_} is required.' })

extend('confirmed', { ...confirmed, message: 'Passwords don’t match' })

extend('vSelectMultiple', {
  validate(value) {
    return value !== undefined && value !== null && Object.keys(value).length > 0;
  },
  message: 'value is not valid'
})

extend('address', {
  validate(value, { currency }) {
    return store?.state?.analytics?.coinData?.addressRegex ? new RegExp(store?.state?.analytics?.coinData?.addressRegex).test(value) : wav.validate(value, currency) || false
  },
  params: ['currency'],
  message: 'Wallet address is not valid'
})

extend('txHash', {
  validate(value) {
    return getIsTxHash(value) || (store?.state?.analytics?.coinData?.txRegex ? new RegExp(store?.state?.analytics?.coinData?.txRegex).test(value) : false)
  },
  message: 'tx hash is not valid'
})

extend('address$OR$txHash', {
  validate(value) {
    return new RegExp(store?.state?.analytics?.coinData?.addressRegex).test(value) || new RegExp(store?.state?.analytics?.coinData?.txRegex).test(value)
  },
  message: 'Wallet address or tx hash is not valid'
})

extend('companyName', {
  validate(value) {
    return /^[a-zA-Z0-9\-.,'&() ]*$/.test(value);
  },
  message: 'Company name contains invalid characters'
})

extend('addressCustomCoinRegExp', {
  validate(value, coinDataLocalRegExp ) {
    if (coinDataLocalRegExp && coinDataLocalRegExp.length) {
      coinDataLocalRegExp[0] = coinDataLocalRegExp[0].replace('$or$', '|',).replace('$comma$', ',',)
      return new RegExp(coinDataLocalRegExp).test(value)
    } else {
      return false
    }
  },
  message: 'Wallet address is not valid'
})

extend('lessThanDateRange', {
  validate(date, { time, value }) {
    const [from, to] = date
    if (value == 'day') {
      const diffInMs = Math.abs(to - from);
      const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));
      return diffInDays < +time
    }
    return true
  },
  params: ['time', 'value', 'label'],
  message: 'Range must be less than {label}'
})
