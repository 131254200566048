export default {
  ugPagination: {
    currentPage: 1,
    perPage: 9,
    totalPages: 1,
  },
  gmPagination: {
    currentPage: 1,
    perPage: 14,
    totalPages: 1,
  },
  ugCards: [],
  gmTable: [],
  ugTooltip: {
    state: true,
    link: 'user-guide',
  },
}
