import apiService from '@/utils/api-client'
import config from "@/utils/appConfig"

export default {
  async getUserGuides(cntx, params) {
    return apiService.get(`getGuides`, { params, baseURL: config.VUE_APP_API_USER_GUIDE_BASE_URL })
      .then((data) => {
        return data
      })
      .catch((error) => {
        return error
      })
  },
  async addUserGuide(cntx, params) {
    return apiService.post(`addGuide`, params, { baseURL: config.VUE_APP_API_USER_GUIDE_BASE_URL })
      .then((data) => {
        return data
      })
      .catch((error) => {
        return error
      })
  },
  async updateUserGuide(cntx, params) {
    return apiService.patch(`updateGuide`, params, { baseURL: config.VUE_APP_API_USER_GUIDE_BASE_URL })
      .then((data) => {
        return data
      })
      .catch((error) => {
        return error
      })
  },
  async deleteUserGuide(cntx, params) {
    return apiService.delete(`deleteGuide`, { baseURL: config.VUE_APP_API_USER_GUIDE_BASE_URL, data: params })
      .then((data) => {
        return data
      })
      .catch((error) => {
        return error
      })
  },
}
