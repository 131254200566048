import apiService from '@/utils/api-client'

export default {
    // eslint-disable-next-line no-unused-vars
    async getNotificationsCount({commit}) {
        try {
            const data = apiService.get('labeling/getNotificationsCount')
            return data
        } catch (error) {
            console.error(error)
        }
    },
}
