var render = function render(){var _vm=this,_c=_vm._self._c;return _c('svg',_vm._g({class:['gl-icon', 
           `gl-icon--${_vm.name}`, 
           {'gl-icon--light': _vm.light, 
            'gl-icon--disabled': _vm.disabled, 
            'gl-icon--warn': _vm.warn,
            'gl-icon--success': _vm.success,
            'gl-icon--link': _vm.link,
            'gl-icon--dark': _vm.dark,
            'gl-icon--hover': _vm.hover,}],attrs:{"height":_vm.height,"width":_vm.width}},_vm.$listeners),[_c('use',{attrs:{"href":_vm.spriteHref}})])
}
var staticRenderFns = []

export { render, staticRenderFns }