<template>
  <div :class="['gl-search-box', { 'gl-search-box__tagging' : tagging }, {'gl-search-box__full': full}, {'gl-search-box__columns-auto': independent}]">
    <div class="gl-search-box__input-wrapper">
      <input
        v-model="innerValue"
        :class="['gl-input__input', 'gl-search-box__input', {'gl-input__input--dark': dark}, {'gl-input__input--grey': grey}, {'gl-search-box__independent': independent}]"
        :disabled="disabledField"
        :placeholder="placeholder"
        :style="[{ height: isMobile ? '32px' : tagging ? '38px' : height }, { minWidth: minWidth }]"
        type="text"
        @focusin="inputFocusIn"
        @keyup.enter="searchValue"
      >
      <div class="flex">
        <transition name="fade">
          <gl-icon
            v-if="hasValue"
            class="gl-search-box__clear-icon"
            :class="[{'mr-4': independent}]"
            :height="24"
            :name="darkClear ? 'clear-large-dark' : 'clear-large'"
            :width="24"
            @click="clearValue"
          />
        </transition>
        <div
          v-if="independent"
          class="gl-search-box__independent-button"
          :class="{'gl-search-box__independent-button--disables': disabled}"
          @click="searchValue"
        >
          <gl-icon
            :height="24"
            name="find"
            :width="24"
          />
        </div>
      </div>
    </div>
    <GlButton
      v-if="!independent"
      dark
      :disabled="disabled"
      :loading="loading"
      :style="[{ height: isMobile ? '32px' : tagging ? '38px' : height }]"
      :title="buttonText"
      @click="searchValue"
    >
      <template
        v-if="isSearch"
        #beforeTitle
      >
        <gl-icon
          class="mr-2"
          height="20"
          name="pp-search"
          width="20"
        />
      </template>
    </GlButton>
  </div>
</template>

<script>
// Components
import GlIcon from '@/components/gl-icon'
import GlButton from "@/components/gl-button";
//mixins
import deviceWidthMixin from '@/assets/mixins/deviceWidthMixin'

export default {
  components: {
    GlIcon,
    GlButton,
  },
  mixins: [deviceWidthMixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    darkClear: {
      type: Boolean,
      default: false,
    },
    grey: {
      type: Boolean,
      default: false,
    },
    full: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    buttonText: {
      type: String,
      default: 'search',
    },
    minWidth: {
      type: String,
      default: 'auto',
    },
    height: {
      type: String,
      default: '30px',
    },
    disabled: {
      type: Boolean,
      default: false
    },
    disabledField: {
      type: Boolean,
      default: false
    },
    tagging: {
      type: Boolean,
      default: false
    },
    independent: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: '',
    },
    isSearch: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      innerValue: this.value,
    }
  },
  computed: {
    hasValue() {
      return Boolean(this.innerValue)
    },
  },
  watch: {
    innerValue(value) {
      this.$emit('input', value)
    },
    value(val) {
      if (val !== this.innerValue) {
        this.innerValue = val
      }
    },
  },
  methods: {
    clearValue() {
      this.innerValue = ''
      this.$emit('clear')
    },
    searchValue() {
      if(!this.disabled) {
        this.innerValue = this.innerValue.trim()
        this.$emit('search', this.innerValue)
      }
    },
    inputFocusIn() {
      this.$emit('inputFocusIn', this.innerValue)
    },
  },
}
</script>
<style>
.gl-search-box .gl-button {
  min-width: 129px;
  width: min-content;
}

.gl-search-box .gl-input__input {
  font-size: 14px;
}
</style>
