import mutations from './mutations'
import state from './state'
import actions from './actions'

export default {
  mutations,
  state,
  actions,
  namespaced: true,
}

