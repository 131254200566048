export default {
  SET_SHRUNK(state, data) {
    state.isShrunk = data
  },
  SET_UNTRACKED_TAGS(state, data) {
    state.hasUntrackedTags = data
  },
  SET_UNTRACKED_TYPE(state, data) {
    state.hasUntrackedType = data
  },
}
