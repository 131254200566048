<template>
  <div
    id="app"
    :class="{'clear-layout': isClearHeader}"
  >
    <div class="flex">
      <div>
        <Sidebar :class="{'hide-header': isClearHeader}" />
      </div>
      <div class="app-root">
        <gl-header :class="{'hide-header': isClearHeader}" />
        <transition name="fade">
          <router-view />
        </transition>
        <UserGuideTooltip />
      </div>
    </div>
    <tooltip />
  </div>
</template>

<script>
// Vuex
import {mapMutations, mapState} from 'vuex'
// Components
import GlHeader from '@/components/layout/gl-header'
import Sidebar from '@/components/sidePanelMenu'
import UserGuideTooltip from '@/components/gl-user-guide-tooltip'
// Utils
import { setDirection } from '@/utils/feature-control'
import { ScoringFormulaList } from '@/utils/globalConstants'
import config from "@/utils/appConfig";
import appConfig from '@/utils/appConfig'
// Services
import defineAbilityFor from "@/services/defineAbilityFor";
// Static
import themes from "@/assets/styles/themes.json";


export default {
  components: {
    GlHeader,
    Sidebar,
    UserGuideTooltip,
  },
  data() {
    return {
      isClearHeader: false,
      themesData: themes,
      resultThemeData: {},
      currentFiatKey: '',
    }
  },
  computed: {
    ...mapState('analytics', ['coinType', 'coinData', 'currencyList']),
    ...mapState('user', ['fiatList']),
  },
  watch: {
    coinType: {
      handler(role) {
        this.updateAbilities(role)
      },
      immediate: true,
    },
    $route: {
      handler(route) {
        this.isClearHeader = route.name === 'register' || route.name === 'login'
      },
      immediate: true,
    },
  },
  async created() {
    this.resultThemeData = this.mergeObjects(this.themesData['default'], this.themesData[config.VUE_APP_THEME])
    const faviconLink = document.getElementById("favicon");
    if (faviconLink && config.VUE_APP_THEME) {
      faviconLink.href = `/assets/themes/${config.VUE_APP_THEME}/favicon.ico`;
    }
    this.setGlobalStyles()

    const manifest = JSON.parse(process.env.VUE_APP_MANIFEST)

    this.SET_COIN_CURRENCY_LIST(manifest)
    const documentHeight = () => {
        const doc = document.documentElement
        doc.style.setProperty('--doc-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', documentHeight)
    documentHeight()
    const coin = this.currencyList[0]
    this.SET_COIN_DATA(coin)
    this.SET_COIN_TYPE(coin.key)

    const initScoringFormula = appConfig.VUE_APP_FORMULA_SELECT
      ? localStorage.getItem('scoringFormula')
        || (ScoringFormulaList.find(f => f.key === appConfig.VUE_APP_SCORING_FORMULA) && ScoringFormulaList.find(f => f.key === appConfig.VUE_APP_SCORING_FORMULA).key)
        || ScoringFormulaList[0].key
      : appConfig.VUE_APP_SCORING_FORMULA

    localStorage.setItem('scoringFormula', initScoringFormula)

    await this.updateAbilities(this.coinType)
    this.currentFiatKey = localStorage.getItem('currentFiatKey') || this.fiatList[0].key
    window.addEventListener('storage', this.checkLocalStorage);
    setDirection(Number(appConfig.VUE_APP_SCORE_REQUEST_DIRECTION))
  },
  methods: {
    ...mapMutations({
      SET_COIN_TYPE: 'analytics/SET_COIN_TYPE',
      SET_COIN_DATA: 'analytics/SET_COIN_DATA',
      SET_COIN_CURRENCY_LIST: 'analytics/SET_COIN_CURRENCY_LIST',
    }),
    checkLocalStorage(event) {
      if (event.key === 'currentFiatKey') {
        const newData = event.newValue || null;
        if (newData !== this.currentFiatKey) {
          this.currentFiatKey = newData;
          location.reload()
        }
      }
    },
    renameObjectKeys(obj, prefix) {
      const result = {};

      for (const key in obj) {
        // eslint-disable-next-line no-prototype-builtins
        if (obj.hasOwnProperty(key)) {
          result[prefix + key] = obj[key];
        }
      }

      return result;
    },
    setGlobalStyles() {
      const { colors } = this.resultThemeData;
      const formattedColors = this.renameObjectKeys(colors, '--')
      const styleTag = document.createElement('style');

      styleTag.textContent = generateCSSFromJSON(formattedColors);
      document.head.appendChild(styleTag);

      function generateCSSFromJSON(jsonData) {
        let css = ':root {\n';

        for (const key in jsonData) {
          // eslint-disable-next-line no-prototype-builtins
          if (jsonData.hasOwnProperty(key)) {
            css += `${key}: ${jsonData[key]};\n`;
          }
        }

        css += '}';
        return css;
      }
    },
    mergeObjects(baseObj, newObj) {
      const result = baseObj;

      for (const key in newObj) {
        // eslint-disable-next-line no-prototype-builtins
        if (newObj.hasOwnProperty(key)) {
          if (typeof newObj[key] === 'object' && !Array.isArray(newObj[key])) {
            result[key] = this.mergeObjects(baseObj[key] || {}, newObj[key]);
          } else {
            // eslint-disable-next-line no-prototype-builtins
            result[key] = baseObj.hasOwnProperty(key) && baseObj[key] === newObj[key] ? baseObj[key] : newObj[key];
          }
        }
      }

      return result;
    },
    updateAbilities() {
      this.$ability.update(defineAbilityFor(this.coinType).rules);
    },
  },
}
</script>
