import apiService from '@/utils/api-client';
import analytics from "@/store/modules/analytics";

export default {
    // eslint-disable-next-line no-unused-vars
    getProfilesList({ commit }) {
        return apiService.get('profiles/getProfiles', { baseURL: analytics.state.coinData.profilesServiceUrl || null })
    },
    // eslint-disable-next-line no-unused-vars
    createProfile({ commit }, name) {
        return apiService.post(`profiles/addProfile`, { name } ,{ baseURL: analytics.state.coinData.profilesServiceUrl || null })
    },
    // eslint-disable-next-line no-unused-vars
    activateProfile({ commit }, { id, recalc = false }) {
        return apiService.post(`profiles/activateProfile`, { id, recalc }  ,{ baseURL: analytics.state.coinData.profilesServiceUrl || null })
    },
    editProfile(ctx, { body }) {
        return apiService.patch(`profiles/updateProfile`, body, { baseURL: analytics.state.coinData.profilesServiceUrl || null })
    },
    deleteProfile(ctx, id) {
        return apiService.delete(`profiles/deleteProfile`, { data: { id } , baseURL: analytics.state.coinData.profilesServiceUrl || null })
    },
}
