import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import sidebarTrackingMixin from "@/assets/mixins/sidebarTrackingMixin";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    redirect: { name: 'analytics' },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../pages/login'),
    meta: {
      title: 'Login'
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "register" */ '../pages/register'),
    meta: {
      title: 'Register'
    },
  },
	{
		path: '/users',
		name: 'users',
		component: () => import(/* webpackChunkName: "users" */ '../pages/users'),
    meta: {
      requiresAuth: true,
      title: 'Users Manager'
    },
	},
  {
    path: '/users/:id',
    name: 'view-user-profile',
    component: () => import(/* webpackChunkName: "view-user-profile" */ '../pages/users/sub-pages/UserProfile.vue'),
    meta: {
      requiresAuth: true,
      title: 'User Profile'
    },
  },
	{
		path: '/alerts',
		name: 'alerts',
		component: () => import(/* webpackChunkName: "alerts" */ '../pages/alerts'),
        meta: {
          requiresAuth: true,
          title: 'Alerts'
        },
	},
    {
		path: '/alerting',
		name: 'alerting',
		component: () => import(/* webpackChunkName: "Alerting" */ '../pages/alerting'),
        meta: {
          requiresAuth: true,
          title: 'Alerting'
        },
	},
  {
    path: '/labeling',
    name: 'labeling',
    component: () => import(/* webpackChunkName: "tagging" */ '../pages/tagging'),
    meta: {
      requiresAuth: true,
      title: 'Labeling'
    },
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import(/* webpackChunkName: "statistics" */ '../pages/statistics'),
    meta: {
      requiresAuth: true,
      title: 'Statistics'
    },
  },
  {
		path: '/scoring',
		name: 'scoring',
		component: () => import(/* webpackChunkName: "scoring" */ '../pages/scoring'),
    meta: {
      requiresAuth: true,
      title: 'Scoring'
    },
	},
  {
    path: '/analytics',
    name: 'analytics',
    component: () => import(/* webpackChunkName: "vision" */ '../pages/analytics'),
    meta: {
      requiresAuth: true,
      title: 'Analytics'
    },
  },
  {
    path: '/cases',
    name: 'cases',
    component: () => import(/* webpackChunkName: "cases" */ '../pages/cases'),
    meta: {
      requiresAuth: true,
      title: 'My Cases'
    },
  },
  {
    path: '/report',
    name: 'report',
    component: () => import(/* webpackChunkName: "report" */ '../pages/report'),
    meta: {
      requiresAuth: true,
      title: 'Report'
    },
  },
  {
    path: '/sankey-analytics',
    name: 'sankey-analytics',
    component: () => import(/* webpackChunkName: "sankey-analytics" */ '../pages/sankey-analytics'),
    meta: {
      requiresAuth: true,
      title: 'Sankey analytics'
    },
  },
  {
    path: '/counterparty',
    name: 'counterparty',
    component: () => import(/* webpackChunkName: "counterparty" */ '../pages/counterparty'),
    meta: {
      requiresAuth: true,
      title: 'Counterparty report'
    },
    children: [
      {
        path: '/counterparty/report/:reportId',
        name: 'view-counterparty-report',
        component: () => import(/* webpackChunkName: "view-counterparty-report" */ '../pages/counterparty/components/ViewReport'),
        meta: {
          requiresAuth: true,
          title: 'Counterparty report'
        },
      },
    ]
  },
  {
    path: '/monitoring',
    name: 'monitoring',
    component: () => import(/* webpackChunkName: "monitoring" */ '../pages/monitoring'),
    meta: {
      requiresAuth: true,
      title: 'Monitoring'
    },
  },
  {
    path: '/api-requests',
    name: 'api-requests',
    component: () => import(/* webpackChunkName: "api-requests" */ '../pages/api-requests'),
    meta: {
      requiresAuth: true,
      title: 'Score requests'
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import(/* webpackChunkName: "profile" */ '../pages/profile'),
    meta: {
      requiresAuth: true,
      title: 'My Account'
    },
  },
  {
    path: '/scoring-profiles',
    name: 'scoring-profiles',
    component: () => import(/* webpackChunkName: "scoring-profiles" */ '../pages/scoring-profiles'),
    meta: {
      requiresAuth: true,
      title: 'Scoring Profiles'
    },
  },
  {
    path: '/entity-explorer',
    name: 'entity-explorer',
    component: () => import(/* webpackChunkName: "entity-explorer" */ '../pages/entity'),
    meta: {
      requiresAuth: true,
      title: 'Entity Explorer'
    },
    children: [
      {
        path: '/entity-explorer/view/:entityId',
        name: 'view-entity-explorer',
        component: () => import(/* webpackChunkName: "view-entity-explorer" */ '../pages/entity/components/ViewEntity'),
        meta: {
          requiresAuth: true,
          title: 'Entity View'
        },
      },
    ]
  },
  {
    path: '/entity-management',
    name: 'entity-management',
    component: () => import(/* webpackChunkName: "entity-management" */ '../pages/entity'),
    meta: {
      requiresAuth: true,
      title: 'Entity management'
    },
    children: [
      {
        path: '/entity-management/view/:entityId',
        name: 'view-entity-management',
        component: () => import(/* webpackChunkName: "view-entity-management" */ '../pages/entity/components/ViewEntity'),
        meta: {
          requiresAuth: true,
          title: 'Entity View'
        },
      },
      {
        path: '/entity-management/create',
        name: 'create-entity-management',
        component: () => import(/* webpackChunkName: "create-entity-management" */ '../pages/entity/components/EntityForm'),
        meta: {
          requiresAuth: true,
          title: 'Create Entity'
        },
      },
      {
        path: '/entity-management/edit/:entityId',
        name: 'edit-entity-management',
        component: () => import(/* webpackChunkName: "edit-entity-management" */ '../pages/entity/components/EntityForm'),
        meta: {
          requiresAuth: true,
          title: 'Edit Entity'
        },
      },
    ]
  },
  {
    path: '/user-guide',
    name: 'user-guide',
    component: () => import(/* webpackChunkName: "user-guide" */ '../pages/user-guide'),
    meta: {
      requiresAuth: true,
      title: 'Knowledge Center'
    },
  },
  {
    path: '/guide-manager',
    name: 'guide-manager',
    component: () => import(/* webpackChunkName: "guide-manager" */ '../pages/guide-manager'),
    meta: {
      requiresAuth: true,
      title: 'Guide Manager'
    },
  },
  {
    path: '*',
    name: '404',
    component: () => import(/* webpackChunkName: "404" */ '../pages/404'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

const adminRoutesList = ['users', 'labeling', 'statistics', 'scoring', 'entity-management', 'guide-manager']

router.beforeEach(async (to, from, next) => {
  if (to.name === 'login') {
    const token = await store.dispatch('user/initToken')
    if (token) {
      next({ name: 'analytics' })
      return
    }
  }

  if (to.name === 'register') {
    await store.dispatch('user/logout')
    next()
    return
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    const token = await store.dispatch('user/initToken')
    if (!token) {
      next({ name: 'login' })
      return
    }

    localStorage.setItem('to.fullPath', to.fullPath)

    const isRejected = await store.dispatch('user/getMe')

    if (isRejected) return

    const isAdmin = await store.getters["user/isAdmin"]

    if (isAdmin) {
      sidebarTrackingMixin.methods.checkUntackedLabels()
    }
  }

  if (adminRoutesList.find(route => route === to.name)) {
    const isAdmin = await store.getters["user/isAdmin"]
  
    if (!isAdmin) {
      next({ path: '/404' })
      return
    }
  }

  next()
})

router.afterEach((to, /* from */) => {
  Vue.nextTick(() => {
    document.title = to.meta.title || 'gl-front-vision'
  })
})

router.onError(error => {
  console.error(error);
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    window.location.reload();
  }
  else if (/Loading.*chunk.*failed./i.test(error.message)) {
    window.location.reload();
  }
});

export default router
