const mobileMaxWidth = 767
const tabletMaxWidth = 1200
const smallDeviceMaxWidth = 1399
const mobileMaxWidthInclude = 768

export default {
    data() {
        return {
            windowWidth: null,
        }
    },
    mounted() {
      this.getWindowWidth()
      window.addEventListener("resize", this.getWindowWidth);
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.getWindowWidth);
    },
    computed: {
      isMobile() {
        return this.windowWidth <= mobileMaxWidth
      },
      isTablet() {
        return this.windowWidth >= mobileMaxWidth && this.windowWidth <= tabletMaxWidth
      },
      isDesktop() {
        return this.windowWidth >= tabletMaxWidth
      },
      isSmallDevice() {
        return smallDeviceMaxWidth <= this.windowWidth
      },
      isMobileInclude() {
        return this.windowWidth <= mobileMaxWidthInclude
      },
    },
    methods: {
        getWindowWidth() {
          this.windowWidth = window.innerWidth;
        },
    }
}
