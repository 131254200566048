import { mapActions, mapMutations } from 'vuex';
import store from "@/store";

export default {
    methods: {
        ...mapMutations({
            SET_UNTRACKED_TAGS: 'sidebar/SET_UNTRACKED_TAGS',
            SET_UNTRACKED_TYPE: 'sidebar/SET_UNTRACKED_TYPE'
        }),
        ...mapActions({
            getTags: 'tagging/getTags',
            getTypes: 'types/getTypes'
        }),
        checkUntackedLabels() {
            store.dispatch('tagging/getTags', { count: 1 }).then(({ data, success }) => {
                if (success) {
                    store.commit('sidebar/SET_UNTRACKED_TAGS', data.tagData && data.tagData.length && data.tagData[0].score === null)
                } else {
                    store.commit('sidebar/SET_UNTRACKED_TAGS', false)
                }
            })
            store.dispatch('types/getTypes', { count: 1 }).then(({ data, success }) => {
                if (success) {
                    store.commit('sidebar/SET_UNTRACKED_TYPE', data.typeData && data.typeData.length && data.typeData[0].score === null)
                } else {
                    store.commit('sidebar/SET_UNTRACKED_TYPE', false)
                }
            })
        },
    }
}
