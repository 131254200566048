import moment from "moment";

export const alarmActiveTo = (date, edgeDuration = 3) => {
    if (!date) return false

    const duration = moment.duration(moment(date).diff(moment(new Date())));

    return duration.asDays() < edgeDuration;
}

export const getLabelRole = (role) => {
    const rolesMap = {
        'customer': 'Regular user',
        'admin': 'Admin',
        'sales-manager': 'Sales Manager',
    };

    return rolesMap[role] || role;
}

export const isWithinPercentage = (a, b, p = 10) => a <= (b * p) / 100;
