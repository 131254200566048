/* eslint-disable no-unused-vars */
import apiService from '@/utils/api-client'
import config from '@/utils/appConfig'
import store from '@/store'

export default {
  // eslint-disable-next-line no-unused-vars
  getCounterpartyList: async function (
    { commit },
    {
      title = undefined,
      from = undefined,
      to = undefined,
      coin = undefined,
      $skip = undefined,
      $limit = undefined,
      archived = undefined,
    },
  ) {
    try {
      return apiService.get('report/counter-party', {
        params: { from, to, $skip, $limit, title },
      })
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  getCounterpartyReportById: async function (
    { commit },
    { id, searchingParams },
  ) {
    try {
      return apiService.get(`report/counter-party/${id}`)
    } catch (error) {
      console.error(error)
    }
  },
  // eslint-disable-next-line no-unused-vars
  async createCounterpartyReport(
    { commit },
    {
      title,
      directions,
      direction,
      address,
      tx_hash,
      token = undefined,
      maxDepth = undefined,
      thresholdAmount = undefined,
      ...extraParams
    },
  ) {
    try {
      return apiService.post('report/counter-party', {
        ...extraParams,
        title,
        directions,
        direction,
        address,
        tx_hash,
        token,
        maxDepth,
        thresholdAmount,
      })
    } catch (error) {
      console.error(error)
    } finally {
      await store.dispatch('user/getMe')
    }
  },

  // eslint-disable-next-line no-unused-vars
  async deleteCounterpartyReport({ commit }, { id }) {
    try {
      return apiService.delete(`report/counter-party/${id}`)
    } catch (error) {
      console.error(error)
    }
  },

  editCounterpartyReport(ctx, { id, title, image, graph }) {
    return apiService.patch(
      `report/counter-party`,
      { id, title, image, graph },
      { baseURL: config.VUE_APP_API_USERS_URL },
    )
  },

  // eslint-disable-next-line no-unused-vars
  counterpartyReportToArchive({ commit }, { ids, status }) {
    return apiService.put(
      'report/counter-party',
      { ids, status: String(status) },
      { baseURL: config.VUE_APP_API_USERS_URL },
    )
  },
}
