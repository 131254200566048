export default {
  SET_ACTIVE_EXPOSURE_PART(state, payload) {
    state.activeExposurePart = payload
    state.activeTypeEntityItem = {}
  },
  SET_EXPOSURE_PART_OPTIONS(state, payload) {
    state.exposurePartOptions = payload
  },
  SET_ACTIVE_PART_BAR(state, payload) {
    state.activePartBar = payload
  },
  SET_ACTIVE_SORT(state, payload) {
    state.activeSort = payload
  },
  SET_ACTIVE_TYPE_ENTITY_ITEM(state, payload) {
    state.activeTypeEntityItem = payload
  },

}
