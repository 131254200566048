export default {
  activeExposurePart: { name: 'Full Exposure', value: 'full' },
  exposurePartOptions: [
    { name: 'Full Exposure', value: 'full' },
    { name: 'Source of Funds', value: 'incoming' },
    { name: 'Use of Funds', value: 'outgoing' },
  ],
  activePartBar: { name: 'Risk Labels', value: 'type' },
  partBarOptions: [
    { name: 'Risk Labels', value: 'type' },
    { name: 'Entity', value: 'entity' },
  ],
  activeTypeEntityItem: {},
}
